/*.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;*/ /* Use the full height of the viewport */
/*}*/

/*.layout-min-height {
  min-height: calc(100vh - 8rem);
}*/

/*.layout-content-wrap {
  flex: 1;
  display: flex;
}
*/
/*.layout-main-container {
  flex: 1;
  padding: 2rem;
  transition: margin-left $transitionDuration;
}

  .layout-main {
    flex: 1 1 auto;

    .card:first-child {
      border-radius: 0;
    }
  }*/

.dashboard .layout-content-wrap {
  position: relative;
  background: linear-gradient(to top right, #0D323D, #103938, #70AF21);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/images/dashboard-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px);
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/images/dashboard-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }
  > * {
    position: relative;
    z-index: 3;
  }

  h1 {
    color: #fff;
  }
}
